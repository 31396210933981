import React from 'react'
import { graphql } from 'gatsby'


import Layout from '../components/Layout/Layout'

import AboutIndex from '../components/AboutIndex/AboutIndex'
import IndexCarousel from '../components/IndexCarousel/IndexCarousel'
import Advantages from '../components/Advantages/Advantages'
import IndexForm from '../components/IndexForm/IndexForm'
import ServicesSlider from '../components/ServicesSlider/ServicesSlider'

import {Container, Row, Col} from 'react-bootstrap'

import '../styles/pages/index.scss'
import ScrollAnimation from 'react-animate-on-scroll';

function IndexPage({data}) {
  
  return (
    <Layout>
        <IndexCarousel/>
        <AboutIndex data={data.contentfulCompanyContacts}/>
        <section className="index--section index-services">
          
          <Container fluid="xl">
            <Row>
              <Col xs={12} md={3} >
              <ScrollAnimation animateIn='fadeIn' animateOnce={true}><h2 className="index-page--title index-services--title">{data.allContentfulPage.nodes[0].title}</h2></ScrollAnimation>
              <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                <div className="index-services--desc" 
                  dangerouslySetInnerHTML={{__html: data.allContentfulPage.nodes[0].shortDescription.childMarkdownRemark.html}}
                />
                </ScrollAnimation>
              </Col>

              <Col xs={12} md={9}>
              <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                  <ServicesSlider slides={data.allContentfulServicePage.nodes}/>
                  </ScrollAnimation>
              </Col>

              
            </Row>
          
          </Container>
          
          
        </section>

        <Advantages
          advantages = {data.allContentfulAdvantage.nodes}
        />
        <IndexForm mail={data.contentfulCompanyContacts.mail}/>
    </Layout>
  )
}

export default IndexPage

export const indexPageQuery = graphql`
query indexPageQuery {
  contentfulCompanyContacts {
    mail
    shortAbout {
      childMarkdownRemark {
        html
      }
    }
  }
  allContentfulAdvantage {
    nodes {
      icon {
        file {
          url
        }
      }
      title
      description
    }
  }
  allContentfulServicePage(sort: {fields: order, order: ASC}) {
    nodes {
      title
      pageDescription
      slug
      heroImage {
        title
        fluid(maxHeight: 450) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
  allContentfulPage(filter: {title: {eq: "Услуги"}}) {
    nodes {
      title
      shortDescription {
        childMarkdownRemark {
          html
        }
      }
    }
  }
}

`