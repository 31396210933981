import React from 'react'
import './AboutIndex.scss'
import { Container, Row, Col } from 'react-bootstrap'

export default ({data}) => {
    return (
        <Container className="about-index" fluid="xl">
        <Row className='justify-content-around align-items-center justify-content-lg-center'>
            <Col className='d-none d-md-block' sm={4} lg={5}>
                        
                    <div className="about-index--svg"/>
                
            </Col>
            <Col md={6}>
                <div className="about-index--text-wrapper">
                    <h2 className="index-page--title">О нас</h2>
                    <div className="about-index--desc" dangerouslySetInnerHTML={{__html: data.shortAbout.childMarkdownRemark.html}}/>
                </div>
            </Col>
        </Row>
        </Container>
    )
}