import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import './Advantages.scss'

function Advantages({ advantages }) {
    return (
        <section className="index--section advantages container">
           <ScrollAnimation animateIn='fadeIn' animateOnce={true}><h2 className="index-page--title advantages--title">Преимущества работы с нами</h2></ScrollAnimation>
            <div className="advantages--grid">
                {
                    advantages.map((item) => {
                        return (
                            <ScrollAnimation animateIn='fadeIn' key={item.title} animateOnce={true}>
                            <div className="advantages--item advantage" >
                                <img className='advantage--icon' src={item.icon.file.url} alt={item.title}/>
                                <div className="advantage--text">
                                    <p className="advantage--title">{item.title}</p>
                                    <p className="advantage--description">{item.description}</p>
                                </div>

                            </div>
                            </ScrollAnimation>
                        )
                    })
                }
            </div>
        </section>
    )
}

export default Advantages