import React from 'react'
import './ServicesSlider.scss'

import Img from 'gatsby-image'

import Slider from "react-slick";

import Button from '../Button/Button'

function ServicesSlider({slides}) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false
        }
      }
    ]
  };
   return (
    <Slider {...settings} className='services-slider'>
      {
        slides.map((slide) => {
          return (
            <div className="services-slide" key={slide.title}>
              
                  <div className="services-slide--wrapper">
                    <Img className="services-slide--img" 
                         fluid = {slide.heroImage.fluid}
                         alt = {slide.heroImage.alt}
                    />
                    <div className="services-slide--info">
                      <h3 className="services-slide--title">{slide.title}</h3>
                      <div className="services-slide--hover">
                        <div className="services-slide--desc">{slide.pageDescription}</div>
                        <Button 
                          as='link' 
                          linkTo={'services/' + slide.slug}
                          text='Подробнее'
                          extraClasses='services-slide--button'
                          />
                      </div>
                    </div>


                  </div>
                  {/* <Link className="services-slide--link" to={'/services/' + slide.slug}>
              </Link> */}
            </div>
          )
        })
      }
    </Slider>
   )
}

export default ServicesSlider