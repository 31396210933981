import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import { Container } from 'react-bootstrap'
import './IndexCarousel.scss'
import Fish from '../../../static/images/fish.jpg'
import Fish2 from '../../../static/images/4.jpg'
import Fish3 from '../../../static/images/5.jpeg'

import { Link } from 'gatsby'
import {Animated} from "react-animated-css";

function IndexCarousel() {
    return (
        <Animated>
        <Container className="index-carousel--container" fluid='xs'>
        <Carousel 
            className='index--section index-carousel' 
            pause='hover'
            prevLabel='Предыдущий слайд'
            nextLabel='Следующий слайд'
            as='section'
        >
            <Carousel.Item interval={4000} className='index-carousel--item'>
                <img
                    className="d-block w-100 index-carousel--image"
                    src={Fish}
                    alt="First slide"
                />
            <Carousel.Caption className='index-carousel--caption'>
                <h3><Link to='services/stairs'>Изготовление деревянных лестниц на заказ</Link></h3>
                {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
            </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={4000} className='index-carousel--item'>
            <img
                className="d-block w-100  index-carousel--image"
                src={Fish2}
                alt="Third slide"
            />
            <Carousel.Caption className='index-carousel--caption'>
                <h3><Link to='services/interiors'>Отделка интерьера деревом</Link></h3>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
            </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={4000} className='index-carousel--item'>
            <img
                className="d-block w-100 index-carousel--image"
                src={Fish3}
                alt="Third slide"
            />
            <Carousel.Caption className='index-carousel--caption'>
                <h3><Link to='services/public-places'>Создание общественных зон</Link></h3>
                {/* <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
            </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
        </Container>
        </Animated>
    )
}

export default IndexCarousel